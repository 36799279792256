import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anatomy`}</h2>
    <p>{`Time Picker component is a user interface element that allows users to select a specific time from a predefined set of options or manually input a time value. It is commonly used in forms, scheduling applications, and settings menus to facilitate time selection.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/timepicker/guideline-time-picker-anatomy.svg",
      "alt": "anatomy guideline textfield"
    }}></img>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Time Input Field`}</strong>{`: A text input field where users can manually enter a time value.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Time Selector`}</strong>{`: A dropdown or input field for selecting the hour, minute, and second portion of the time.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`AM/PM Selector (Optional)`}</strong>{`: If applicable, a dropdown or input field for selecting the AM or PM designation.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Time Picker Icon/Button`}</strong>{`: An icon or button that opens the time picker modal when clicked.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Time Picker Modal`}</strong>{`: A modal or dropdown panel that displays the available time options for selection.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Caption (optional)`}</strong>{`: provides contextual assistance like helper text, in any area where the user might need additional information to provide the right input.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Time Now Button (Optional)`}</strong>{`: Allows users to set the time based on the current time`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`OK/Apply Button`}</strong>{`: Confirms the selected time and closes the modal, saving the chosen time value.`}</p>
      </li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`The Time Picker component can be used in various contexts, such as:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Form Input: Use it in forms where users need to specify a time for appointments, reservations, or other time-sensitive data.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Scheduling Applications: Implement it in scheduling applications to allow users to set event times easily.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Settings: Utilize it in user settings menus to enable users to customize time-related preferences.`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`Common places buttons appear`}</strong>{` : Mobile app homepage , website homepage and other pages when needed etc.`}</p>
    <div className="row1">
  <div className="double-column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#429777"
        }}>
      When to use
    </p>
    <ul>
      <li>Time Input is Required: Users need to provide a time value as part of data input.</li>
      <li>Precision is Necessary: Precise time selection, down to the minute, is important.</li>
      <li>Standardization is Needed: Ensuring a consistent time format across the application is required.</li>
      <li>
        User-Friendly Interaction: Users are more comfortable with a visual time selection method rather than manual
        input.
      </li>
      <li>
        Mobile-Friendly Design: On mobile devices, where typing can be cumbersome, a Time Picker simplifies time entry.
      </li>
    </ul>
  </div>
  <div className="column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#e42313"
        }}>
      When not to use
    </p>
    <ul>
      <li>Time Input is Required: Users need to provide a time value as part of data input.</li>
      <li>Precision is Necessary: Precise time selection, down to the minute, is important.</li>
      <li>Standardization is Needed: Ensuring a consistent time format across the application is required.</li>
      <li>
        User-Friendly Interaction: Users are more comfortable with a visual time selection method rather than manual
        input.
      </li>
      <li>
        Mobile-Friendly Design: On mobile devices, where typing can be cumbersome, a Time Picker simplifies time entry.
      </li>
    </ul>
  </div>
    </div>
    <div className="divi" />
    <h2>{`Accessibility`}</h2>
    <p>{`To ensure accessibility, follow these guidelines:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Provide keyboard navigation and focus management within the Time Picker.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Implement ARIA roles and attributes to enhance screen reader compatibility.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Ensure that all Time Picker elements have clear and descriptive labels.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Use sufficient color contrast for text and UI elements.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Test the Time Picker with assistive technologies to verify usability.`}</p>
      </li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do" caption="Provide clear and concise labels & icon for all Time Picker components." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/timepicker/guideline-time-picker-a11y-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="The label & icon that being provide is not clear & concise so it became ambiguous" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/timepicker/guideline-time-picker-a11y-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Content`}</h2>
    <p>{`When creating Time Picker :`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Clear Labels: Ensure all components are labeled clearly and descriptively.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Example: “Hours,” “Minutes,” “AM/PM.”`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Placeholder Text: Use placeholder text in the time input field to provide an example or format hint.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Example: “Enter time (e.g., 3:30 PM).”`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Error Messages: Provide informative error messages if users enter invalid time values.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Example: “Please enter a valid time (e.g., 3:30 PM).”`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Help Text: Include tooltips or help text to assist users in understanding how to use the Time Picker.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Example: “Click the clock icon to select a time.”`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`AM/PM Clarification (if applicable): Explain the purpose of the AM/PM selector.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Example: “Select ‘AM’ for morning times and ‘PM’ for afternoon/evening times.”`}</p>
      </li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do" caption="Provide and allow users to switch between AM and PM." aspectRatio="3:4" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/timepicker/guideline-time-picker-content-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Didn’t provide and allow users to switch between AM and PM." aspectRatio="3:4" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/timepicker/guideline-time-picker-content-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      